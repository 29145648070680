import * as React from 'react';
import { FareFeaturePaymentType } from '@websky/graphql';
import DesktopFlightRouteModal from './components/DesktopFlightModalContent/DesktopFlightModalContent';
import FareGroup from './components/FareGroup/FareGroup';
import { FareLockIcon, icons } from './components/icons';
import PassengerFormFields from './components/PassengerForm/FormFields/FormFields';
import { renderCheckoutLinksLabel } from './components/InfoLinksLabel/InfoLinksLabel';
import OrderInsuranceBlockSlotUpsale from '@websky/core/src/Checkout/components/Checkout/OrderInsuranceBlockSlot/OrderInsuranceBlockSlot.upsale';
// import CheckoutServicesV2 from '@websky/core/src/Checkout/components/Checkout/ServicesV2/ServicesV2';
import CheckoutAdditionalService from '@websky/core/src/CheckoutAdditionalService/CheckoutAdditionalServiceV2/CheckoutAdditionalServiceV2';
import Locations from './components/Schedule/Locations/Locations';
import Passengers from '@websky/core/src/SearchForm/components/SearchForm/Passengers/Passengers';
import { D2_CanBeIncrease } from './components/SearchForm/components/SearchForm/Passengers/utils';
export const renders = Object.assign(Object.assign({}, icons), { DesktopFlightRouteModal, FareGroupGrid: FareGroup, RenderFareLockIcon: FareLockIcon, PassengerFormFields, Included: icons[FareFeaturePaymentType.Free], Paid: icons[FareFeaturePaymentType.Pay], renderCheckoutLinksLabel, CheckoutInsurance: OrderInsuranceBlockSlotUpsale, CheckoutAdditionalService, ScheduleLocations: Locations, SearchFormPassengers: props => React.createElement(Passengers, Object.assign({}, props, { customCanBeIncrease: D2_CanBeIncrease })) });
