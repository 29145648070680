import { FareFeaturePaymentType } from '@websky/graphql';
import * as React from 'react';
export const icons = {
    Included: () => (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM14.7559 8.08926C15.0814 7.76382 15.0814 7.23618 14.7559 6.91074C14.4305 6.58531 13.9028 6.58531 13.5774 6.91074L8.90351 11.5846L6.42259 9.10373C6.09715 8.77829 5.56951 8.77829 5.24408 9.10373C4.91864 9.42916 4.91864 9.9568 5.24408 10.2822L8.90351 13.9417L14.7559 8.08926Z", fill: "currentColor", style: { transform: 'translateX(2px)' } }))),
    Person: () => (React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { id: "Union", fillRule: "evenodd", clipRule: "evenodd", d: "M18.6667 9.33268C18.6667 11.911 16.5784 13.9993 14 13.9993C11.4217 13.9993 9.33335 11.911 9.33335 9.33268C9.33335 6.75435 11.4217 4.66602 14 4.66602C16.5784 4.66602 18.6667 6.75435 18.6667 9.33268ZM4.66669 19.8327C4.66669 16.7293 10.885 15.166 14 15.166C17.115 15.166 23.3334 16.7293 23.3334 19.8327V23.3327H4.66669V19.8327Z", fill: "currentColor" }))),
    Star: () => (React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { id: "Star", d: "M13.0685 3.26145C13.3997 2.41295 14.6003 2.41295 14.9315 3.26145L17.0995 8.81536C17.2416 9.17947 17.5829 9.4274 17.9731 9.45006L23.9251 9.79565C24.8344 9.84845 25.2055 10.9904 24.5008 11.5676L19.8887 15.3457C19.5863 15.5934 19.456 15.9945 19.555 16.3726L21.0656 22.1401C21.2964 23.0213 20.325 23.727 19.5583 23.2352L14.5399 20.0163C14.2109 19.8053 13.7891 19.8053 13.4601 20.0163L8.44167 23.2352C7.67498 23.727 6.70362 23.0213 6.9344 22.1401L8.44499 16.3726C8.54403 15.9945 8.41368 15.5934 8.11132 15.3457L3.49917 11.5676C2.79455 10.9904 3.16557 9.84845 4.07489 9.79565L10.0269 9.45006C10.4171 9.4274 10.7584 9.17947 10.9005 8.81536L13.0685 3.26145Z", fill: "currentColor" }))),
    [FareFeaturePaymentType.Pay]: () => (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { id: "\u00E2\u0082\u00BD", d: "M12.5479 13.7783H6V12.3984H12.5479C13.1865 12.3984 13.7109 12.2959 14.1211 12.0908C14.5312 11.8857 14.833 11.5957 15.0264 11.2207C15.2256 10.8457 15.3252 10.4033 15.3252 9.89355C15.3252 9.47754 15.2314 9.0791 15.0439 8.69824C14.8623 8.31738 14.5664 8.00391 14.1562 7.75781C13.752 7.51172 13.2158 7.38867 12.5479 7.38867H9.50684V18.7969H7.81934V6H12.5479C13.5146 6 14.3291 6.16699 14.9912 6.50098C15.6533 6.8291 16.1543 7.28613 16.4941 7.87207C16.8398 8.45215 17.0127 9.12012 17.0127 9.87598C17.0127 10.6963 16.8398 11.3994 16.4941 11.9854C16.1543 12.5654 15.6533 13.0107 14.9912 13.3213C14.3291 13.626 13.5146 13.7783 12.5479 13.7783ZM12.4424 14.9824V16.3711H6V14.9824H12.4424Z", fill: "currentColor" }))),
    Choosed: () => (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM17.7071 9.70711C18.0976 9.31658 18.0976 8.68342 17.7071 8.29289C17.3166 7.90237 16.6834 7.90237 16.2929 8.29289L10.6842 13.9016L7.70711 10.9245C7.31658 10.5339 6.68342 10.5339 6.29289 10.9245C5.90237 11.315 5.90237 11.9482 6.29289 12.3387L10.6842 16.73L17.7071 9.70711Z", fill: "white" }))),
    SeatSelection: () => (React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { d: "M11.843 23.9831L13.1789 21.3858H8.82366L4.99927 8.57633V3.70886C4.99927 2.76508 5.78629 2 6.75713 2C7.72798 2 8.515 2.76508 8.515 3.70886V8.57633L11.3533 16.9788H19.4965C20.7483 16.9788 21.7632 17.9653 21.7632 19.1823C21.7632 20.3992 20.7483 21.3858 19.4965 21.3858H15.4354L14.0996 23.9831H19.0983V25.9451H13.0904L13.0622 26L12.9493 25.9451H6.70006V23.9831H11.843Z", fill: "currentColor" }))),
    CheckedBaggage: () => (React.createElement("svg", { width: "28", height: "28", viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6.58038 4.54316H8.27218V2.32741C8.27218 1.04422 9.25529 0 10.4639 0H16.3694C17.5778 0 18.5612 1.04422 18.5612 2.32741V4.54305H20.2529C21.3098 4.54305 22.1667 5.45306 22.1667 6.57541V24.0007C22.1667 25.1236 21.3096 26.0333 20.2529 26.0333H19.9963V27.5087C19.9963 27.7803 19.789 28 19.5338 28H17.3452C17.0895 28 16.8825 27.7799 16.8825 27.5087V26.0333H9.95128V27.5087C9.95128 27.7803 9.74381 28 9.48862 28H7.30023C7.04426 28 6.83757 27.7799 6.83757 27.5087V26.0333H6.5806C5.5237 26.0333 4.66675 25.1233 4.66675 24.0007V6.57552C4.66653 5.45294 5.52347 4.54316 6.58038 4.54316ZM9.73365 4.54305H17.1003V2.32741C17.1003 1.89968 16.7725 1.55161 16.3697 1.55161H10.4642C10.0614 1.55161 9.73365 1.89968 9.73365 2.32741V4.54305ZM7.99872 8.43709C7.47066 8.43709 7.04259 8.87294 7.04259 9.4106C7.04259 9.94825 7.47066 10.3841 7.99872 10.3841H18.8348C19.3629 10.3841 19.7909 9.94825 19.7909 9.4106C19.7909 8.87294 19.3629 8.43709 18.8348 8.43709H7.99872ZM7.99872 12.3311C7.47066 12.3311 7.04259 12.767 7.04259 13.3046C7.04259 13.8423 7.47066 14.2781 7.99872 14.2781H18.8348C19.3629 14.2781 19.7909 13.8423 19.7909 13.3046C19.7909 12.767 19.3629 12.3311 18.8348 12.3311H7.99872ZM7.04259 17.1987C7.04259 16.661 7.47066 16.2252 7.99872 16.2252H18.8348C19.3629 16.2252 19.7909 16.661 19.7909 17.1987C19.7909 17.7363 19.3629 18.1722 18.8348 18.1722H7.99872C7.47066 18.1722 7.04259 17.7363 7.04259 17.1987ZM7.99872 20.1192C7.47066 20.1192 7.04259 20.5551 7.04259 21.0927C7.04259 21.6304 7.47066 22.0662 7.99872 22.0662H18.8348C19.3629 22.0662 19.7909 21.6304 19.7909 21.0927C19.7909 20.5551 19.3629 20.1192 18.8348 20.1192H7.99872ZM26.8334 1.16667H21.0001L19.8334 3.5H26.8334V1.16667Z", fill: "currentColor" })))
};
export const FareLockIcon = () => (React.createElement("svg", { width: "79", height: "78", viewBox: "0 0 79 78", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { clipPath: "url(#clip0_20247_69637)" },
        React.createElement("circle", { cx: "39.4999", cy: "39.5", r: "38", transform: "rotate(-45 39.4999 39.5)", fill: "#CCD9EC" }),
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M34.9167 35.3333V28.9167C34.9167 26.3854 36.9687 24.3333 39.5 24.3333C42.0313 24.3333 44.0833 26.3854 44.0833 28.9167V35.3333H34.9167ZM39.5 20.6667C44.0563 20.6667 47.75 24.3603 47.75 28.9167V35.3333H53.25V57.3333H25.75V35.3333H31.25V28.9167C31.25 24.3603 34.9437 20.6667 39.5 20.6667Z", fill: "#0041A0" }),
        React.createElement("path", { d: "M40.5192 48.8942H33.5V46.8173H40.4731C41.0634 46.8173 41.54 46.7212 41.9028 46.5288C42.2656 46.3301 42.53 46.0577 42.696 45.7115C42.862 45.359 42.945 44.9551 42.945 44.5C42.945 44.0769 42.859 43.6827 42.6868 43.3173C42.5146 42.9519 42.2502 42.6571 41.8935 42.4327C41.543 42.2083 41.088 42.0962 40.5284 42.0962H37.9089V54H35.3724V40H40.5192C41.5707 40 42.4654 40.1923 43.2033 40.5769C43.9412 40.9615 44.5069 41.4904 44.9005 42.1635C45.3002 42.8365 45.5 43.609 45.5 44.4808C45.5 45.4038 45.2971 46.1955 44.8912 46.8558C44.4915 47.516 43.9197 48.0224 43.1756 48.375C42.4377 48.7212 41.5523 48.8942 40.5192 48.8942ZM40.8882 49.7981V51.8846H33.5V49.7981H40.8882Z", fill: "#E5ECF5" }),
        React.createElement("circle", { opacity: "0.2", cx: "39.4999", cy: "39.5", r: "38", transform: "rotate(-45 39.4999 39.5)", fill: "url(#paint0_linear_20247_69637)" })),
    React.createElement("rect", { x: "1", y: "0.5", width: "77", height: "77", rx: "38.5", stroke: "#668DC6" }),
    React.createElement("defs", null,
        React.createElement("linearGradient", { id: "paint0_linear_20247_69637", x1: "39.4999", y1: "39.5", x2: "39.4999", y2: "77.5", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#256CBF", stopOpacity: "0" }),
            React.createElement("stop", { offset: "0.0001", stopColor: "#256CBF" }),
            React.createElement("stop", { offset: "1", stopColor: "#256CBF", stopOpacity: "0" })),
        React.createElement("clipPath", { id: "clip0_20247_69637" },
            React.createElement("rect", { x: "1.5", y: "1", width: "76", height: "76", rx: "38", fill: "white" })))));
