import * as React from 'react';
import { sortLocationsByName } from '@websky/core/src/Schedule/utils';
import LocationsRoot from '@websky/core/src/Schedule/components/Locations/Locations';
const PRIORITY_CITIES_IATA = ['CEE'];
const Locations = props => {
    const locationsSorting = (a, b) => {
        if (PRIORITY_CITIES_IATA.includes(a.iata)) {
            return -1;
        }
        else if (PRIORITY_CITIES_IATA.includes(b.iata)) {
            return 1;
        }
        return sortLocationsByName(a, b);
    };
    return React.createElement(LocationsRoot, Object.assign({}, props, { locationsSorting: locationsSorting }));
};
export default Locations;
