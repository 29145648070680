import cn from 'classnames';
import Shared from './components/Shared';
import AdditionalServiceBannerStyles from './components/AdditionalServiceBannerStyles/AdditionalServiceBannerStyles.css';
import SeatMap from './components/SeatMap/SeatMap.css';
import Seat from './components/SeatMap/Seat/Seat.css';
import StartSearch from './components/StartSearch/StartSearch.css';
import QuickSearchForm from './components/QuickSearchForm/QuickSearchForm.css';
import SearchForm from './components/SearchForm/SearchForm.css';
import SearchFormSegment from './components/SearchForm/Segment/Segment.css';
import Checkin from './components/Checkin/Checkin.css';
import AddPassenger from './components/AddPassenger/AddPassenger.css';
import Baggage from './components/Baggage/Baggage.css';
import BaggageCard from './components/BaggageCard/BaggageCard.css';
import BaggageList from './components/BaggageList/BaggageList.css';
import BaggageSegment from './components/BaggageSegment/BaggageSegment.css';
import BaggagePassenger from './components/BaggagePassenger/BaggagePassenger.css';
import BaggageTotalPriceBlock from './components/BaggageTotalPriceBlock/BaggageTotalPriceBlock.css';
import IncludedBaggage from './components/IncludedBaggage/IncludedBaggage.css';
import AdditionalBaggage from './components/AdditionalBaggage/AdditionalBaggage.css';
import SpecialEquipment from './components/SpecialEquipment/SpecialEquipment.css';
import UpgradeBaggageDialog from './components/UpgradeBaggageDialog/UpgradeBaggageDialog.css';
import UpgradeBaggageDialogHeader from './components/UpgradeBaggageDialog/BaggageHeader/BaggageHeader.css';
import UpgradeBaggageDialogFooter from './components/UpgradeBaggageDialog/BaggageFooter/BaggageFooter.css';
import PromoLoader from './components/PromoLoader/PromoLoader.css';
import MealService from './components/MealService/MealService.css';
import RetrieveBooking from './components/RetrieveBooking/RetrieveBooking.css';
import VisaForm from './components/VisaForm/VisaForm.css';
import BoardingPassDesk from './components/BoardingPassDesk/BoardingPassDesk.css';
import SearchFormDatepicker from './components/SearchForm/Datepicker/Datepicker.css';
import Results from './components/Results/Results.css';
import ResultsCalendar from './components/Results/Calendar/Calendar.css';
import ResultsSortings from './components/Results/Sortings/Sortings.css';
import ResultsFlightList from './components/Results/FlightList/FlightList.css';
import Step from './components/Step/Step.css';
import WeekCalendar from './components/WeekCalendar/WeekCalendar.css';
import DesktopFlightRouteSegment from './components/DesktopFlightRoute/Segment/Segment.css';
import DesktopFlightModalContent from './components/DesktopFlight/DesktopFlightModalContent/DesktopFlightModalContent.css';
import FareGroup from './components/FareGroup/FareGroup.css';
import FareGroupGrid from './components/FareGroup/FareGroupGrid/FareGroupGrid.css';
import MobileFlight from './components/MobileFlight/MobileFlight.css';
import FareLock from './components/FareLock/FareLock.css';
import PriceGraph from './components/PriceGraph/PriceGraph.css';
import SelectedFareGroup from './components/DesktopFlight/SelectedFareGroup/SelectedFareGroup.css';
import DesktopSummaryFlight from './components/DesktopSummaryFlight/DesktopSummaryFlight.css';
import PassengerForm from './components/PassengerForm/PassengerForm.css';
import Checkout from './components/Checkout/Checkout.css';
import OrderBooking from './components/OrderCard/OrderBooking/OrderBooking.css';
import OrderCard from './components/OrderCard/OrderCard.css';
import PassengerDetails from './components/PassengerDetails/PassengerDetails.css';
import CheckoutAdditionalService from './components/CheckoutAdditionalService/CheckoutAdditionalService.css';
import ServiceSummary from './components/ServiceSummary/ServiceSummary.css';
import CompareFares from './components/CompareFares/CompareFares.css';
import Exchange from './components/Exchange/Exchange.css';
import Refund from './components/Refund/Refund.css';
import LoginPage from './components/LoginPage/LoginPage.css';
import Consents from './components/Consents/Consents.css';
import InsurancePage from './components/InsurancePage/InsurancePage.css';
import SegmentTabs from './components/SegmentTabs/SegmentTabs.css';
import DirectionsDialog from './components/LocationAutocomplete/DirectionsDialog/DirectionsDialog.css';
import FlightInfoSegment from './components/DesktopFlight/FlightInfoSegment/FlightInfoSegment.css';
import RegisteredPassenger from './components/RegisteredPassenger/RegisteredPassenger.css';
import MetaSearchLoader from './components/MetaSeachLoader/MetaSeachLoader.css';
import Schedule from './components/Schedule/Schedule.css';
import RefundSummary from './components/RefundSummary/RefundSummary.css';
const theme = {
    AdditionalServiceBanner: {
        AdditionalServiceBannerStyles: {
            wrapper: cn(AdditionalServiceBannerStyles.baggage__banner_wrapper, Checkin.additionalServiceBanner),
            baggageBanner: AdditionalServiceBannerStyles.baggage__banner,
            text: AdditionalServiceBannerStyles.baggage__banner_text,
            image: AdditionalServiceBannerStyles.baggage__banner_image,
            disclaimer: AdditionalServiceBannerStyles.baggage__banner_disclaimer,
            disclaimer__icon: AdditionalServiceBannerStyles.baggage__banner_disclaimer__icon
        }
    },
    Link: {
        Link: {
            link: Shared.Button.link
        }
    },
    MobileFlight: {
        FlightStyles: {
            price: MobileFlight.price,
            price__money: MobileFlight.price__money,
            selectedFare__priceWrapper: MobileFlight.selectedFare__priceWrapper,
            selectedFare__price: MobileFlight.selectedFare__price
        }
    },
    FareLock: {
        FareLock: {
            description_block: FareLock.description_block,
            switch_block: FareLock.switch_block,
            root: FareLock.root
        },
        SwitchBlock: {
            switch_label: FareLock.switch_label
        },
        DescriptionBlock: {
            title: FareLock.title,
            description: FareLock.description,
            warning: FareLock.warning
        }
    },
    Input: {
        Input: {
            input: Shared.Inputs.input,
            input_filled: Shared.Inputs.input_filled,
            input_focused: Shared.Inputs.input_focused,
            real_input: Shared.Inputs.real_input,
            textField__root: cn(Shared.Inputs.textField__root, PassengerForm.textField__root),
            input_error: Shared.Inputs.input_error,
            label_error: Shared.Inputs.label_error,
            textField_focused: Shared.Inputs.textField_focused,
            textField_standard: Shared.Inputs.textField_standard,
            hint: Shared.Inputs.hint
        }
    },
    Button: {
        Button: {
            button_text: CheckoutAdditionalService.button_text
        },
        ActionButton: {
            button: Shared.Button.action_button
        }
    },
    CloseIcon: {
        CloseIcon: {
            wrapper: Shared.Button.closeIcon
        }
    },
    Counter: {
        Counter: {
            button: Shared.Counter.button,
            value: Shared.Counter.value,
            counter: Shared.Counter.counter,
            button_disabled: Shared.Counter.button_disabled
        }
    },
    Money: {
        Money: {
            money: Shared.Money.money
        }
    },
    Switch: {
        SwitchV2: {
            label: Shared.SwitchV2.label,
            active: Shared.SwitchV2.active
        },
        Switch: {
            wrapper: Shared.Switch.wrapper,
            active: Shared.Switch.active,
            label: Shared.Switch.label
        }
    },
    ServiceWithSegments: {
        ServiceWithSegments: {
            service: cn(Shared.ServiceWithSegments.service, Checkin.serviceWithSegments__service),
            wrapper: Shared.ServiceWithSegments.wrapper
        }
    },
    Toolbar: {
        Toolbar: {
            toolbar: Shared.Toolbar.toolbar,
            inner: Shared.Toolbar.inner,
            backButton: Shared.Toolbar.backButton,
            actions: Shared.Toolbar.actions,
            backButton__wrapper: Shared.Toolbar.backButton__wrapper,
            price: Shared.Toolbar.price,
            button: Shared.Toolbar.continueButton
        }
    },
    MobileToolbar: {
        MobileToolbar: {
            wrapper: Shared.ToolbarMobile.toolbar,
            button_back: Shared.ToolbarMobile.backButton,
            button_continue: cn(Shared.ToolbarMobile.continueButton, Shared.Button.CTN)
        }
    },
    Stepbar: {
        Stepbar: {
            stepbar: Shared.Stepbar.stepbar,
            cart: Shared.Stepbar.cart,
            inner: Shared.Stepbar.inner
        },
        Step: {
            id: Shared.Stepbar.id,
            step: cn(Shared.Stepbar.step, Step.step),
            step_active: Shared.Stepbar.step_active,
            step_clickable: Shared.Stepbar.step_clickable,
            step_passed: Shared.Stepbar.step_passed,
            label: Shared.Stepbar.label,
            step_disabled: Shared.Stepbar.step_disabled
        }
    },
    MobileStepbar: {
        MobileStepbarStyles: {
            stepbar: Shared.StepbarMobile.stepbar
        },
        StepbarHeader: {
            header: Shared.StepbarMobile.header,
            price__wrapper: Shared.StepbarMobile.price__wrapper,
            price: Shared.StepbarMobile.price,
            expand: Shared.StepbarMobile.expand,
            price_title: Shared.StepbarMobile.price_title
        }
    },
    Modal: {
        Modal: {
            modal_rounded: Shared.Modal.modal_rounded,
            closeIcon: cn(Shared.Button.closeIcon, Shared.Button.close__popup, Shared.Modal.closeIcon),
            modal: Shared.Modal.modal,
            mobile: Shared.Modal.modal,
            closeIcon_mobileFullScreen: Shared.Button.close__fullscreen
        }
    },
    SlideBottom: {
        SlideBottomStyles: {
            header: Shared.SlideBottom.header,
            container: Shared.SlideBottom.container
        }
    },
    BaggagePage: {
        BaggagePage: {
            equipment_baggage: UpgradeBaggageDialog.equipment_baggage,
            additionalBaggage: Baggage.additionalBaggage,
            items: Baggage.items,
            additional: Baggage.additional,
            baggage: Baggage.baggage,
            newBaggage: Baggage.newBaggage
        },
        MobileBaggageRules: {
            wrapper: Baggage.mobileBaggageRules
        }
    },
    AdditionalBaggage: {
        AdditionalBaggage: {
            header: AdditionalBaggage.header
        },
        PromoMessage: {
            root: Baggage.promoMessage
        }
    },
    Baggage: {
        Baggage: {
            root: Baggage.root,
            name: cn(Baggage.name, UpgradeBaggageDialogHeader.baggage_name),
            size: cn(Baggage.size, UpgradeBaggageDialogHeader.baggage_size)
        },
        BaggageIcon: {
            root: Baggage.icon
        }
    },
    BaggageCard: {
        BaggageCard: {
            header: BaggageCard.header,
            title: BaggageCard.title,
            description: BaggageCard.description,
            icon: BaggageCard.icon,
            price: BaggageCard.price,
            button: cn(Shared.Button.optionEdit, Shared.Button.small)
        }
    },
    BaggageList: {
        BaggageCount: {
            root: Shared.Button.count_button
        },
        BaggageDirection: {
            root: Shared.Button.count_button_direction
        },
        BaggageList: {
            count: BaggageList.count,
            footer: BaggageList.footer,
            add: BaggageList.button
        }
    },
    BaggageTotalPriceBlock: {
        BaggageTotalPriceBlock: {
            // control: Shared.Button.CTN,
            // price: Baggage.baggageTotalPriceBlock__price
            price: BaggageTotalPriceBlock.price,
            control__edit: cn(Shared.Button.primary, Shared.Button.small, BaggageTotalPriceBlock.control__edit),
            control: cn(Shared.Button.optionEdit, Shared.Button.small, BaggageTotalPriceBlock.control)
        }
    },
    IncludedBaggage: {
        IncludedBaggage: {
            header: IncludedBaggage.header
        }
    },
    UpgradeBaggageDialog: {
        UpgradeBaggageDialog: {
            root: UpgradeBaggageDialog.root,
            body: UpgradeBaggageDialog.body,
            modalHeader: UpgradeBaggageDialogHeader.header,
            close: Shared.Button.close__popup
        },
        BaggageDialogHeader: {
            baggage_wrapper: UpgradeBaggageDialogHeader.baggage_wrapper,
            category_title: UpgradeBaggageDialogHeader.category__title,
            category__price: UpgradeBaggageDialog.category__price,
            category_description: UpgradeBaggageDialogHeader.category__description,
            categories__item: UpgradeBaggageDialogHeader.category__item,
            perFlight: UpgradeBaggageDialog.perFlight
        },
        BaggageFooter: {
            clear: UpgradeBaggageDialogFooter.clear,
            cancel_btn: cn(Shared.Button.optionEdit, Shared.Button.small),
            confirm_btn: cn(Shared.Button.primary, Shared.Button.small)
        },
        HeaderEquipmentItem: {
            wrapper: UpgradeBaggageDialogHeader.category__item_wrapper,
            active: UpgradeBaggageDialogHeader.category__item_active
        },
        MobileBaggageSelector: {
            container: UpgradeBaggageDialog.container,
            selected: UpgradeBaggageDialog.mobile__selected,
            selector: UpgradeBaggageDialog.mobile__selector,
            footer: UpgradeBaggageDialog.mobile__footer_info,
            price: UpgradeBaggageDialog.mobile__footer_price
        },
        BaggagePassenger: {
            root: BaggagePassenger.root,
            counter_wrapper: UpgradeBaggageDialog.passenger__counter_wrapper
        },
        BaggageSegment: {
            root: BaggageSegment.root,
            price: UpgradeBaggageDialog.segment__price
        }
    },
    SpecialEquipment: {
        SpecialEquipment: {
            flight_direction: SpecialEquipment.direction,
            flight_count: SpecialEquipment.count,
            flight_icon: SpecialEquipment.icon
        }
    },
    Meal: {
        Meal: {
            meal: MealService.meal__root,
            price: cn(Shared.Option.edit, MealService.meal__price),
            count: cn(Shared.Button.count_button, MealService.meal__count),
            popup__close: Shared.Button.close__popup,
            popup: Shared.Modal.modal,
            popup__scrollPaper: Shared.Modal.modal__scrollPaper,
            footer__icons: MealService.footer__icons,
            name: MealService.name
        },
        Details: {
            wrapper: Shared.Modal.wrapper,
            selector: MealService.details_selector,
            button: Shared.Button.primary,
            total: MealService.details__total,
            price: MealService.details__price,
            description: MealService.details__description,
            passenger: MealService.details_passenger
        }
    },
    SubsidySuggestPopup: {
        SubsidySuggestPopup: {
            newSearch: cn(Shared.Button.width_100),
            continueButton: cn(Shared.Button.width_100),
            closeButton: Shared.Button.close__popup
        }
    },
    Passengers: {
        PassengerStyles: {
            age: SearchForm.passengerAge,
            decrease: Shared.Counter.button,
            increase: Shared.Counter.button,
            counter: Shared.Counter.value,
            counter_disabled: Shared.Counter.button_disabled
        },
        PassengersStyles: {
            item_FNN: SearchForm.passengers__itemFNN
        }
    },
    Passenger: {
        Switch: {
            element: Shared.Switch.element,
            element_active: Shared.Switch.element_active,
            element_error: Shared.Switch.element_error,
            error: PassengerForm.switch_error
        },
        Select: {
            error: Shared.Inputs.select_error,
            input: Shared.Inputs.select_input
        }
    },
    Checkout: {
        CheckoutStyles: {
            gdsTotalPrice: Checkout.gdsTotalPrice,
            checkout: Shared.ServiceWithSegments.passengersService,
            summary__wrp: cn(Shared.ServiceWithSegments.passengersSummary, Checkout.summary__wrp),
            messages: cn(Shared.ServiceWithSegments.passengersSummary),
            form: Checkout.form,
            selectedFlight__container: Checkout.selectedFlight__container,
            checkoutServices: Checkout.checkoutServices,
            summary: Checkout.summary
        },
        PassengersToolbar: {
            checkbox: Shared.Checkbox.checkbox,
            checkbox_checked: Shared.Checkbox.checkbox_checked,
            label: cn(Checkout.passengersToolbar__label, Shared.Checkbox.label)
        },
        PassengersStyles: {
            passengers: Checkout.passengers
        },
        ThanksBanner: {
            title: Checkout.thanksBanner_title
        }
    },
    Contacts: {
        ContactsStyles: {
            checkbox: Shared.Checkbox.checkbox,
            checkbox_checked: Shared.Checkbox.checkbox_checked,
            checkbox__label: Shared.Checkbox.label,
            form: PassengerForm.contacts_form,
            description__text: PassengerForm.contacts_description__text,
            header: PassengerForm.contacts_header,
            contacts: PassengerForm.contacts,
            description__header: PassengerForm.description__header
        }
    },
    SeatMap: {
        DoubleSeat: {
            container: SeatMap.doubleSeat,
            switch__label: SeatMap.doubleSeat__label,
            description: SeatMap.doubleSeat__description
        },
        Passenger: {
            payload: SeatMap.passenger__payload,
            clear: SeatMap.passenger__clear,
            item: SeatMap.passenger__item,
            item_active: SeatMap.passenger__item_active,
            main: SeatMap.passenger__main,
            avatar: SeatMap.passenger__avatar,
            passengerName: SeatMap.passengerName,
            select: SeatMap.passenger__select,
            money: SeatMap.passenger__money,
            numbers: SeatMap.passenger__numbers,
            item_recommend: SeatMap.passenger__item_recommend
        },
        PassengersStyles: {
            recommend: SeatMap.passengers__recommend,
            recommend__icon: SeatMap.passengers__recommend__icon,
            recommend__label: SeatMap.passengers__recommend__label,
            passengers__button: cn(Shared.Button.Action, SeatMap.passengers__recommend__button),
            passengers__button_clear: cn(Shared.Button.Neitral, SeatMap.passengers__recommend__button_clear)
        },
        Controls: {
            controls: Shared.ToolbarMobile.backButton,
            back: Shared.ToolbarMobile.backButton,
            button: Shared.Button.Action
        },
        ComponentStyles: {
            wrapper: Shared.ServiceWithSegments.service,
            header_mobile: SeatMap.header_mobile,
            seatsInfo: SeatMap.seatsInfo,
            seatsInfo_content: SeatMap.seatsInfo_content,
            map: SeatMap.map,
            segmentTabs_tab: SeatMap.segmentTabs_tab
            // unavailable__img: SeatMap.unavailable__img
        },
        SeatMapStyles: {
            seatMap: SeatMap.seatMap,
            seatMap__wrapper: SeatMap.seatMap__wrapper,
            row__serviceHeader: SeatMap.row__serviceHeader,
            row__serviceHeader_preferred: SeatMap.row__serviceHeader_preferred,
            row__serviceHeader_comfort: SeatMap.row__serviceHeader_comfort,
            row__serviceHeader__wrapper: SeatMap.row__serviceHeader__wrapper,
            row__serviceHeader__serviceName: SeatMap.row__serviceHeader__serviceName,
            row__serviceHeader_free: SeatMap.row__serviceHeader_free,
            wings: SeatMap.wings,
            row_serviceHeader_from: SeatMap.row_serviceHeader_from,
            row: SeatMap.row,
            seat: SeatMap.seat,
            row__rowPartWrp: SeatMap.row__rowPartWrp,
            row__PartWrpWithTechFacilities: SeatMap.row__PartWrpWithTechFacilities
        },
        AirplaneInfoStyles: {
            sunInfo: SeatMap.sunInfo,
            sunInfo__inner: SeatMap.sunInfo__inner
        },
        AirplaneStyles: {
            airplane: SeatMap.airplane,
            airplane__info: SeatMap.airplane__info,
            airplane__name: SeatMap.airplane__name,
            details: SeatMap.airplane__details,
            seatsDetails: SeatMap.seatsDetails,
            seatsDetails_icon: SeatMap.seatsDetails_icon,
            segment: SeatMap.segment
        },
        SeatsRecommendPopup: {
            content: SeatMap.recomend__content,
            header: SeatMap.recomend__header,
            price__wrapper: SeatMap.recomend__price__wrapper,
            main__icon: SeatMap.recomend__main_icon,
            controls: SeatMap.recomend__controls,
            actions: SeatMap.recomend__actions,
            cancel: SeatMap.recomend__cancel,
            confirm: SeatMap.recomend__confirm,
            control_free: SeatMap.freeSeat__seat,
            control_selected: SeatMap.recomend_selected
        },
        SeatMapDisclaimerStyles: {
            disclaimer: SeatMap.disclaimer
        },
        SeatsInfoStyles: {
            seat: SeatMap.seatsInfo__seat,
            price__wrapper: SeatMap.seatsInfo__price__wrapper,
            feature: SeatMap.seatsInfo__feature,
            title: SeatMap.seatsInfo__title,
            description: SeatMap.seatsInfo__description,
            freeSeat__container: SeatMap.freeSeat__container,
            freeSeat__seat: SeatMap.freeSeat__seat,
            mobile: SeatMap.mobile,
            seat__info: SeatMap.seat__info,
            header: SeatMap.seat__header,
            seat_free: SeatMap.seat_free,
            seat_economy: SeatMap.seat_economy,
            seat_business: SeatMap.seat_business,
            seat_preferred: SeatMap.seat_preferred,
            note: SeatMap.seatsInfo__note,
            note__header: SeatMap.seatsInfo__note__header,
            additionalInfo: SeatMap.seatsInfo__additionalInfo
        },
        SeatServiceSlide: {
            single: Shared.Button.Action,
            serviceInfo__selectable: SeatMap.serviceInfo__selectable,
            seat: SeatMap.serviceInfo__seat
        },
        SeatStyles: {
            seat: Seat.seat,
            number: Seat.number,
            seat_business: Seat.seat_business,
            seat_preferred: Seat.seat_front,
            seat_free: Seat.seat_free,
            seat_unavailable: Seat.seat_unavailable,
            seat_occupied: Seat.seat_occupied,
            seat_unexistent: Seat.seat_unexistent
        },
        SeatTooltipStyles: {
            tooltip: SeatMap.tooltip,
            popper: SeatMap.tooltip__popper,
            container: SeatMap.tooltip__container,
            info: SeatMap.tooltip__info,
            tooltip_withInfo: SeatMap.tooltip_withInfo
        },
        SeatPrice: {
            root__text: SeatMap.root__text
        },
        MobilePassenger: {
            container: SeatMap.mobilePassenger__container
        }
    },
    FareGroup: {
        FareGroup: {
            button: cn(Shared.Button.CTN, FareGroup.button),
            fareGroup_white: FareGroup.fareGroup_white,
            fareGroup_Business: FareGroup.fareGroup_Business,
            option__icon: FareGroup.option__icon,
            option__title: FareGroup.option__title,
            option_unavailable: FareGroup.option_unavailable,
            option__value: FareGroup.option__value,
            option_free: FareGroup.option_free,
            keyFeature__icon: FareGroup.keyFeature__icon,
            keyFeature__value: FareGroup.keyFeature__value,
            price: FareGroup.price,
            keyFeature: FareGroup.keyFeature,
            keyFeature_paid: FareGroup.keyFeature_paid,
            keyFeature_unavailable: FareGroup.keyFeature_unavailable,
            options: FareGroup.options,
            fareGroup_recommended: FareGroup.fareGroup_recommended,
            info: FareGroup.info,
            title: FareGroup.title,
            upgradeOptionWrapper: FareGroup.upgradeOptionWrapper,
            upgradeOffer: FareGroup.upgradeOffer,
            upgradeOffer__link: FareGroup.upgradeOffer__link
        },
        RecommendedBadge: {
            badge: FareGroup.recommendedBadge_badge
        },
        FareGroupGrid: {
            header: FareGroupGrid.header,
            container: FareGroupGrid.container
        }
    },
    Results: {
        Results: {
            toolbar__backButton: Shared.Toolbar.backButton,
            toolbar__nextButton: Shared.Toolbar.continueButton,
            results: Results.results
        },
        Sortings: {
            mobile__list__button: cn(Shared.Button.width_100, ResultsSortings.mobile__list__button),
            sortBy: ResultsSortings.sortBy,
            title: ResultsSortings.title,
            currentSorting: ResultsSortings.currentSorting,
            listItem: ResultsSortings.listItem,
            listItem_active: ResultsSortings.listItem_active,
            paper: ResultsSortings.paper,
            mobile__closeButton: ResultsSortings.mobile__closeButton
        },
        Calendar: {
            legNumber: ResultsCalendar.legNumber,
            title__cities: ResultsCalendar.title__cities,
            title__text: ResultsCalendar.title__text,
            diagram: ResultsCalendar.diagram
        },
        FlightsList: {
            controls__wrapper: ResultsFlightList.controls__wrapper,
            leg: ResultsFlightList.leg
        },
        MobilePriceGraph: {
            dates: Results.mobilePriceGraph_dates
        }
    },
    DesktopFlightRoute: {
        FlightPriceStyles: {
            button: Shared.Button.CTN,
            price__container: DesktopFlightRouteSegment.price__container,
            price__money: DesktopFlightRouteSegment.price__money,
            best: DesktopFlightRouteSegment.flightPrice_best
        },
        SegmentStyles: {
            flightInfo__date: DesktopFlightRouteSegment.flightInfo__date,
            flightInfo__airline: DesktopFlightRouteSegment.flightInfo__airline,
            segment: DesktopFlightRouteSegment.segment,
            planeInfo: DesktopFlightRouteSegment.planeInfo,
            plane_return: DesktopFlightRouteSegment.plane_return,
            totalDuration: DesktopFlightRouteSegment.totalDuration
        },
        RouteArrowStyles: {
            flightNumber: DesktopFlightRouteSegment.flightNumber,
            timeInRoute: DesktopFlightRouteSegment.timeInRoute
        },
        DesktopFlightRouteStyles: {
            flight: DesktopFlightRouteSegment.flight,
            flight_opened: DesktopFlightRouteSegment.flight_opened,
            flight_readonly: Exchange.flight_readonly
        },
        StopsInfoStyles: {
            stop: DesktopFlightRouteSegment.stop
        },
        PointStyles: {
            date__container: DesktopFlightRouteSegment.date__container
        }
    },
    Amenities: {
        Amenities: {
            amenities__item: DesktopFlightRouteSegment.amenities__item
        }
    },
    WeekCalendar: {
        DayStyles: {
            date__info: WeekCalendar.date__info,
            date: WeekCalendar.date,
            day: WeekCalendar.day,
            price: WeekCalendar.price,
            day_best: WeekCalendar.day_best,
            day_selected: WeekCalendar.day_selected
        },
        WeekCalendarStyles: {
            dayWrapper_selected: WeekCalendar.dayWrapper_selected,
            dayWrapper: WeekCalendar.dayWrapper
        }
    },
    SearchForm: {
        Passengers: {
            decrease: Shared.Counter.counter
        },
        StartSearch: {
            startSearch: cn(StartSearch.startSearch, Shared.Button.CTN),
            startSearch_iconMode: cn(StartSearch.startSearch_iconMode, Shared.Button.CTN)
        },
        SearchForm: {
            searchForm: SearchForm.searchForm
        },
        Datepicker: {
            dates__to_focused: SearchFormDatepicker.dates__to_focused,
            dates__back_focused: SearchFormDatepicker.dates__back_focused,
            day__price: SearchFormDatepicker.day__price,
            highlightedDate_withPrice: SearchFormDatepicker.highlightedDate_withPrice
        },
        Segment: {
            cell: SearchFormSegment.cell,
            location__value: SearchFormSegment.location__value,
            input: SearchFormSegment.input
        },
        Value: {
            placeholder: SearchForm.placeholder
        }
    },
    SearchFormDatepicker: {
        SearchFormDatepickerStyles: {
            footer__buttons: SearchFormDatepicker.footer__buttons,
            footer__done: SearchFormDatepicker.footer__done,
            footer: SearchFormDatepicker.footer,
            tabs: SearchFormDatepicker.tabs,
            footer__clear: SearchFormDatepicker.footer__clear,
            dialog__close: SearchFormDatepicker.dialog__close,
            dialog__activeDate: SearchFormDatepicker.dialog__activeDate,
            dialog__dates: SearchFormDatepicker.dialog__dates,
            dialog__dateBack: SearchFormDatepicker.dialog__dateBack,
            dialog__bar: SearchFormDatepicker.dialog__bar
        },
        DayStyles: {
            day_withPrice: SearchFormDatepicker.day_withPrice,
            day_endHover_withPrice: SearchFormDatepicker.day_endHover_withPrice,
            day_startHover_withPrice: SearchFormDatepicker.day_startHover_withPrice
        }
    },
    Datepicker: {
        DayStyles: {
            day__inside: SearchFormDatepicker.day__inside,
            day_today: SearchFormDatepicker.day_today,
            day_weekend: SearchFormDatepicker.day_weekend,
            day_startPeriod: SearchFormDatepicker.day_startPeriod,
            day_endPeriod: SearchFormDatepicker.day_endPeriod,
            day: cn(SearchFormDatepicker.day, Exchange.calendar__day),
            day_notAvailable: SearchFormDatepicker.day_notAvailable,
            day_selected: SearchFormDatepicker.day_selected,
            day_inPeriod: SearchFormDatepicker.day_inPeriod,
            day_startHover: SearchFormDatepicker.day_startHover,
            day_endHover: SearchFormDatepicker.day_endHover
        },
        MonthStyles: {
            week: SearchFormDatepicker.week
        },
        MonthHeaderStyles: {
            monthHeader: SearchFormDatepicker.monthHeader,
            monthHeader__select_month: SearchFormDatepicker.monthHeader__select_month,
            monthHeader__select_year: SearchFormDatepicker.monthHeader__select_year
        },
        WeeklyHeaderStyles: {
            day: cn(SearchFormDatepicker.weekHeader_day, Exchange.weekHeader_day),
            week: SearchFormDatepicker.week
        }
    },
    QuickSearchForm: {
        QuickSearchForm: {
            form: QuickSearchForm.form
        },
        Summary: {
            dates: QuickSearchForm.summary_dates,
            route: QuickSearchForm.summary_route,
            passengers: QuickSearchForm.summary_passengers
        }
    },
    LocationAutocomplete: {
        Dropdown: {
            allDirections__button: cn(Shared.Button.width_100)
        },
        DirectionsDialog: {
            dialog: DirectionsDialog.dialog,
            country: DirectionsDialog.country,
            label: DirectionsDialog.label,
            countries: DirectionsDialog.countries,
            cities: DirectionsDialog.cities,
            paper: DirectionsDialog.paper,
            city: DirectionsDialog.city,
            country_active: DirectionsDialog.country_active,
            iata: DirectionsDialog.iata
        }
    },
    DesktopFlight: {
        ModalContent: {
            paper_dropdown: DesktopFlightModalContent.paper_dropdown,
            content_wrapper: cn(DesktopFlightModalContent.content_wrapper, FareGroup.content_wrapper),
            fareGroup__button_business: DesktopFlightModalContent.fareGroup__button_business,
            fareGroup__button_active: DesktopFlightModalContent.fareGroup__button_active,
            buttons: DesktopFlightModalContent.buttons,
            slider_allOnce: cn(DesktopFlightModalContent.slider_allOnce, FareGroup.slider_allOnce),
            fareGroup__button: DesktopFlightModalContent.fareGroup__button
        },
        SelectedFareGroup: {
            button: SelectedFareGroup.button,
            button__rules: SelectedFareGroup.button__rules
        },
        FlightInfoSegment: {
            locations: FlightInfoSegment.locations
        }
    },
    PromoCode: {
        PromoCode: {
            modal__scrollBody: Shared.Modal.modal__scrollBody,
            modal: Shared.Modal.promoCode_modal,
            closeButton: Shared.Button.close__popup
        }
    },
    FlightCard: {
        FlightCard: {
            aircraft: DesktopFlightModalContent.flightCard_aircraft,
            aircraft__image: DesktopFlightModalContent.flightCard_aircraft__image,
            airline: DesktopFlightModalContent.flightCard_airline
        }
    },
    PassengerForm: {
        Disabilities: {
            checkbox: Shared.Checkbox.checkbox,
            checkbox_checked: Shared.Checkbox.checkbox_checked,
            switchControl: PassengerForm.disabilities_switchControl,
            container: PassengerForm.disabilities_container
        },
        LoyaltyGroup: {
            loyalty__desc: PassengerForm.loyalty__desc,
            container_loyalty: PassengerForm.container_loyalty
        },
        PopupWrapper: {
            confirm: PassengerForm.popupWrapper_confirm,
            cancel: PassengerForm.popupWrapper_cancel,
            disabled: PassengerForm.popupWrapper_disabled
        },
        Header: {
            filled: PassengerForm.header_filled,
            number: PassengerForm.header_number,
            header: PassengerForm.header
        },
        PassengerForm: {
            form: PassengerForm.form
        },
        Group: {
            icon: PassengerForm.group__icon,
            withIcons: PassengerForm.group_withIcons,
            container: PassengerForm.group_container
        }
    },
    DesktopSummaryFlight: {
        Header: {
            header__icon: DesktopSummaryFlight.header__icon,
            title: DesktopSummaryFlight.header__title,
            header__cell: DesktopSummaryFlight.header__cell,
            price: DesktopSummaryFlight.header__price,
            header: DesktopSummaryFlight.header__cell,
            fareGroup__name: DesktopSummaryFlight.fareGroup__name,
            travelTime: DesktopSummaryFlight.header__travelTime
        },
        SummaryFlight: {
            feature__icon: DesktopSummaryFlight.feature__icon,
            feature__text: DesktopSummaryFlight.feature__text,
            info: DesktopSummaryFlight.info
        }
    },
    DesktopFlightInfo: {
        Segment: {
            duration: DesktopSummaryFlight.segment_duration,
            airline: DesktopSummaryFlight.segment_airline,
            transfer: DesktopSummaryFlight.segment_transfer
        },
        Point: {
            point: DesktopSummaryFlight.point
        }
    },
    OrderCard: {
        OrderBooking: {
            root: OrderBooking.root,
            type_review: OrderBooking.type_review,
            orderCode: OrderBooking.orderCode,
            security_code: OrderBooking.security_code
        },
        OrderPassengers: {
            control: OrderCard.control,
            paper: OrderCard.orderPassengers_paper
        },
        OrderTickets: {
            link: OrderCard.link
        },
        OrderCard: {
            container: OrderCard.container,
            type_review: OrderCard.type_review
        },
        OrderStatus: {
            confirmed: OrderCard.status_confirmed,
            icon: OrderCard.status_icon
        }
    },
    CheckoutAdditionalService: {
        CheckoutAdditionalService: {
            service: CheckoutAdditionalService.service,
            service_active: CheckoutAdditionalService.service_active,
            header: CheckoutAdditionalService.header,
            icon: CheckoutAdditionalService.icon,
            header__text: CheckoutAdditionalService.header__text,
            header__price: CheckoutAdditionalService.header__price,
            main: CheckoutAdditionalService.main,
            content: CheckoutAdditionalService.content,
            buttons: CheckoutAdditionalService.buttons,
            add: cn(Shared.Button.action_button, CheckoutAdditionalService.add)
        },
        CheckoutAdditionalServiceMeal: {
            meal: CheckoutAdditionalService.meal
        },
        CheckoutAdditionalServiceBaggage: {
            baggage: CheckoutAdditionalService.baggage
        },
        Seats: {
            seats: CheckoutAdditionalService.seats
        },
        Insurance: {
            insurance: CheckoutAdditionalService.insurance
        },
        Business: {
            business: CheckoutAdditionalService.business
        },
        Loyalty: {
            loyalty: CheckoutAdditionalService.loyalty
        },
        Aeroexpress: {
            aeroexpress: CheckoutAdditionalService.aeroexpress
        },
        PriorityBoarding: {
            priority: CheckoutAdditionalService.priority
        },
        InsuranceUpsale: {
            paper: CheckoutAdditionalService.insuranceUpsale_paper,
            container: CheckoutAdditionalService.insuranceUpsale_container,
            button: CheckoutAdditionalService.insuranceUpsale_button,
            change: CheckoutAdditionalService.insuranceUpsale_change
        }
    },
    PassengersDetails: {
        PassengersDetails: {
            item: PassengerDetails.item,
            close: PassengerDetails.close,
            name: PassengerDetails.name
        }
    },
    ServiceSummary: {
        ServiceSummaryOption: {
            wrapper: ServiceSummary.option_wrapper
        }
    },
    Checkin: {
        Checkin: {
            extras: Checkin.extras,
            wrapper: Checkin.wrapper,
            seats: cn(Checkin.seats, SeatMap.checkinSeats)
        },
        ConditionsModal: {
            button: Checkin.conditionsModal__button
        },
        Passengers: {
            wrapper: Checkin.passengers__wrapper
        },
        PassengersHeader: {
            container: Checkin.passengersHeader__container,
            title: Checkin.passengersHeader__title
        },
        VisaForm: {
            header: VisaForm.header,
            buttons: VisaForm.buttons,
            skipVisaButton: VisaForm.skipVisaButton
        },
        PassengersContent: {
            slider: Checkin.checkin__slider
        },
        Order: {
            container: Checkin.order__container
        }
    },
    AddPassenger: {
        AddPassenger: {
            addButton: AddPassenger.addButton,
            submitButton: cn(Shared.Button.CTN, AddPassenger.submitButton)
        }
    },
    PromoLoader: {
        PromoLoader: {
            promo__content: PromoLoader.promo__content,
            card__city: PromoLoader.card__city,
            promo__icon: PromoLoader.promo__icon,
            wrapper: PromoLoader.wrapper,
            promo: PromoLoader.promo,
            loader__wrp: PromoLoader.loader__wrp,
            text: PromoLoader.text,
            promo__image: PromoLoader.promo__image
        }
    },
    MealService: {
        List: {
            item: MealService.list__item,
            item_selected: MealService.list__item_selected,
            item__icon: MealService.list__item__icon
        }
    },
    RetrieveBooking: {
        RetrieveBooking: {
            button: cn(Shared.Button.CTN, RetrieveBooking.button),
            notification_context: RetrieveBooking.notification_context
        }
    },
    CheckinRules: {
        CheckinRules: {
            rules__label: Checkin.checkinRules__label,
            rules__button: cn(Shared.Button.CTN, Checkin.checkinRules__button)
        }
    },
    Segment: {
        BoardingPassDesk: {
            footer: BoardingPassDesk.footer,
            col: BoardingPassDesk.col,
            footer__item: BoardingPassDesk.footer__item
        }
    },
    PriceGraph: {
        LegendsStyles: {
            money: PriceGraph.legends_money,
            line: PriceGraph.legends_line,
            line_min: PriceGraph.legends_line_min
        },
        PriceGraphStyles: {
            graphHeader__money: PriceGraph.graphHeader__money,
            graphHeader: PriceGraph.graphHeader,
            button: PriceGraph.button
        },
        DayStyles: {
            day: PriceGraph.day,
            day_focused: PriceGraph.day_focused,
            day_notAvailable: PriceGraph.day_notAvailable
        }
    },
    CompareFares: {
        CompareFares: {
            fareHeadingItem: CompareFares.fareHeadingItem
        }
    },
    Exchange: {
        Inner: {
            header: Exchange.header,
            header__text: Exchange.header__text,
            closeIcon: Exchange.closeIcon,
            container: Exchange.container,
            header__inner: Exchange.header__inner
        },
        Chip: {
            chip: Exchange.chip,
            close: Exchange.chip_close
        },
        Choice: {
            label: Exchange.choice_label,
            label_selected: Exchange.choice_label_selected,
            container: Exchange.choice_container,
            labelInner: Exchange.choice_labelInner
        },
        CommonSelect: {
            buttons: Exchange.commonSelect_buttons,
            button: Exchange.commonSelect_button,
            back: Exchange.commonSelect_back
        },
        SearchFlights: {
            leg: Exchange.searchFlights_leg,
            route: Exchange.searchFlights_route,
            controls: Exchange.searchFlights_controls,
            header: Exchange.searchFlights_header
        },
        Calendar: {
            buttons: Exchange.calendar_buttons,
            modal: Exchange.calendar_modal,
            calendar: Exchange.calendar,
            datepicker__closeIcon: Exchange.datepicker__closeIcon
        },
        Datepicker: {
            header: Exchange.datepicker_header
        },
        Sort: {
            title: Exchange.sort_title,
            sortings: Exchange.sortings
        },
        SelectedFlights: {
            header: Exchange.selectedFlights_header,
            header__name: Exchange.selectedFlights_header__name,
            price: Exchange.selectedFlights_price,
            money: Exchange.selectedFlights_money,
            total: Exchange.selectedFlights_total,
            total__money: Exchange.selectedFlights_total__money,
            charge: Exchange.selectedFlights_charge,
            flight__container: Exchange.selectedFlights_flight__container
        },
        Notice: {
            container: Exchange.notice_container,
            reject: Exchange.notice_reject,
            icon: Exchange.notice_icon,
            header: Exchange.notice_header
        },
        CreateRequest: {
            label: Exchange.createRequest_label,
            submit: Exchange.createRequest_submit,
            header: Exchange.createRequest_header
        },
        Selections: {
            wrapper: Exchange.selections_wrapper
        },
        RequestForm: {
            order: Exchange.requestForm_order,
            header: Exchange.requestForm_header,
            created: Exchange.requestForm_created
        },
        RequestControls: {
            button: Exchange.requestControls_button,
            refuse: Exchange.requestControls_refuse,
            container: Exchange.requestControls_container
        }
    },
    RefundSummary: {
        CommonContent: {
            totalRefund: RefundSummary.totalRefund
        },
        RefundSummary: {
            selectedServices: RefundSummary.selectedServices
        }
    },
    Refund: {
        Wrapper: {
            wrapper: Refund.wrapper,
            header: Refund.header,
            title: Refund.title,
            header_wrapper: Refund.header_wrapper,
            close__icon: Refund.close__icon,
            close__text: Refund.close__text
        },
        Refund: {
            buttons: Refund.buttons
        },
        Breadcrumbs: {
            breadcrumb: Exchange.chip
        },
        StepTitle: {
            text: Refund.stepTitle_text,
            step: Refund.stepTitle_step,
            icon: Refund.stepTitle_icon
        },
        Passenger: {
            passenger: Refund.passenger,
            active: Refund.active
        },
        Passengers: {
            passengers: Refund.passengers,
            passenger: Refund.passenger
        },
        StepsButtons: {
            button: Refund.stepsButtons_button,
            backButton: Refund.stepsButtons_backButton,
            wrapper: Refund.stepsButtons_wrapper
        },
        ReasonForm: {
            reason: Refund.reasonForm_reason,
            reasons: Refund.reasonForm_reasons,
            groupTitle: Refund.reasonForm_groupTitle,
            groupWrapper: Refund.reasonForm_groupWrapper
        },
        Reason: {
            radio: Refund.reason_radio
        },
        Note: {
            restriction: Exchange.notice_reject,
            note: cn(Exchange.notice_container, Refund.note),
            icon: Exchange.notice_icon,
            title: Exchange.notice_header
        },
        RequestForm: {
            wrapper: Refund.requestForm_wrapper,
            notes: Refund.requestForm_notes,
            agree: Refund.requestForm_agree,
            formLabel: Refund.requestForm_formLabel,
            formName: Refund.requestForm_formName,
            formEmail: Refund.requestForm_formEmail,
            formTel: Refund.requestForm_formTel,
            formComment: Refund.requestForm_formComment
        },
        RefundPaper: {
            paper: Refund.paper
        },
        Ticket: {
            price: Refund.ticket_price,
            prices: Refund.ticket_prices,
            refund__request: Refund.refund__request
        },
        Status: {
            icon: Refund.status_icon,
            waiting: Refund.status_waiting
        },
        OrderRefundStatus: {
            right: Refund.orderRefundStatus_right,
            waiting: Refund.orderRefundStatus_waiting,
            status: Refund.orderRefundStatus_status,
            title: Refund.orderRefundStatus_title,
            button: Refund.orderRefundStatus_button,
            rejected: Refund.orderRefundStatus_rejected,
            processing: Refund.orderRefundStatus_processing,
            success: Refund.orderRefundStatus_success
        },
        RefundInfoModal: {
            button: Refund.refundInfoModal_button,
            no: Refund.refundInfoModal_buttonNo
        }
    },
    TicketRequest: {
        TicketRequest: {
            request: Refund.ticketRequest__request,
            date: Refund.ticketRequest__date
        }
    },
    MobileSegmentInfo: {
        MobileSegmentFlat: {
            title: MobileFlight.mobileSegmentFlat_title,
            image: Exchange.mobileSegmentFlat_image
        }
    },
    LoginPage: {
        LoginForm: {
            button_wrapper: LoginPage.button_wrapper
        }
    },
    Consents: {
        Consents: {
            container: Consents.container
        },
        Checkbox: {
            label: Consents.label
        }
    },
    InsurancePage: {
        InsurancePage: {
            wrapper: InsurancePage.page__wrapper,
            content: InsurancePage.page__content,
            header: InsurancePage.page__header,
            title: InsurancePage.page__title,
            subheader: InsurancePage.page__subheader,
            insurancesList: InsurancePage.page__insurancesList
        },
        InsuranceProduct: {
            title: InsurancePage.product__title,
            popular: InsurancePage.product__popular,
            content: InsurancePage.product__content,
            footer: InsurancePage.product__footer,
            formControl: InsurancePage.product__formControl,
            description: InsurancePage.product__description,
            options: InsurancePage.product__options,
            moreInfo: InsurancePage.product__moreInfo,
            linksWrapper: InsurancePage.product__linksWrapper
        },
        InsuranceUpsaleModal: {
            button: InsurancePage.upsaleModal__button,
            root: InsurancePage.upsaleModal__root
        },
        InsurancePrice: {
            price: InsurancePage.price__price,
            content: InsurancePage.price__content,
            bold: InsurancePage.price_bold,
            regular: InsurancePage.price_regular,
            light: InsurancePage.price_light,
            forAllPassengers: InsurancePage.price_forAllPassengers
        },
        SelectForm: {
            root: InsurancePage.selectForm__root,
            label: InsurancePage.selectForm__label,
            checked: InsurancePage.selectForm__checked,
            radio: InsurancePage.selectForm__radio,
            price: InsurancePage.selectForm__price,
            priceContent: InsurancePage.selectForm__priceContent
        },
        Banner: {
            icons: InsurancePage.banner__icons,
            popularBadge: InsurancePage.banner__popularBadge
        },
        OptionsList: {
            list: cn(InsurancePage.optionsList__list, CheckoutAdditionalService.optionsList__list)
        }
    },
    SegmentTabs: {
        SegmentTabs: {
            segments: SegmentTabs.segmentTab__segments
        },
        SegmentTab: {
            segment: SegmentTabs.segmentTab__segment,
            checked: SegmentTabs.segmentTab__checked,
            segment_active: SegmentTabs.segmentTab__segment_active
        }
    },
    RegisteredPassenger: {
        Controls: {
            container: RegisteredPassenger.controls__container,
            control: RegisteredPassenger.controls__control,
            controlImg: RegisteredPassenger.controls__controlImg
        }
    },
    MetasearchLoader: {
        MetasearchLoader: {
            logo: MetaSearchLoader.logo
        }
    },
    CoreSchedule: {
        Weekpicker: {
            months__prev: Schedule.months__prev,
            months__next: Schedule.months__next,
            month__week: Schedule.month__week,
            hoverDate: Schedule.weekpicker__hoverDate,
            day__day: Schedule.weekpicker__day,
            day__today: Schedule.weekpicker__dayToday,
            day__selected: Schedule.weekpicker__daySelected,
            day__inside: Schedule.weekpicker__dayInside
        }
    }
};
export default theme;
