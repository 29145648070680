import Button from './Button/Button.css';
import Option from './Option/Option.css';
import Toolbar from './Toolbar/Toolbar.css';
import ToolbarMobile from './ToolbarMobile/ToolbarMobile.css';
import Stepbar from './Stepbar/Stepbar.css';
import StepbarMobile from './StepbarMobile/StepbarMobile.css';
import Counter from './Counter/Counter.css';
import Money from './Money/Money.css';
import Switch from './Switch/Switch.css';
import SwitchV2 from './SwitchV2/SwitchV2.css';
import Checkbox from './Checkbox/Checkbox.css';
import ModalPriceBlock from './ModalPriceBlock/ModalPriceBlock.css';
import Inputs from './Inputs/Inputs.css';
import ServiceWithSegments from './ServiceWithSegments/ServiceWithSegments.css';
import TabsList from './TabsList/TabsList.css';
import Modal from './Modal/Modal.css';
import SlideBottom from './SlideBottom/SlideBottom.css';
const Shared = {
    Button: Button,
    Counter: Counter,
    Option: Option,
    Money: Money,
    Switch: Switch,
    SwitchV2: SwitchV2,
    Checkbox: Checkbox,
    ModalPriceBlock: ModalPriceBlock,
    TabsList: TabsList,
    Toolbar: Toolbar,
    ToolbarMobile: ToolbarMobile,
    Stepbar: Stepbar,
    StepbarMobile: StepbarMobile,
    Inputs: Inputs,
    ServiceWithSegments: ServiceWithSegments,
    Modal: Modal,
    SlideBottom: SlideBottom
};
export default Shared;
