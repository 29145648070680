import { isInfant } from '@websky/core/src/SearchForm/components/SearchForm/Passengers/utils';
export const D2_CanBeIncrease = (passenger, config, passengers, totalCount, maxPassengersCount = 9) => {
    var _a;
    if (totalCount >= maxPassengersCount) {
        return false;
    }
    const passengerCount = passengers[passenger.code];
    if (passenger.maxCount) {
        return passengerCount < passenger.maxCount;
    }
    if (((_a = passenger.noAvailableWith) === null || _a === void 0 ? void 0 : _a.length) &&
        passenger.noAvailableWith.some(noAvailableCode => passengers[noAvailableCode] > 0)) {
        return false;
    }
    if (!passenger.isAdult) {
        const adultCount = config.reduce((count, current) => count + (current.isAdult ? passengers[current.code] || 0 : 0), 0);
        if (isInfant(passenger) && passengerCount >= adultCount) {
            return false;
        }
        else if (adultCount <= 0) {
            if (passenger.maxCountWithoutAdult && passengerCount < passenger.maxCountWithoutAdult) {
                return true;
            }
            return false;
        }
    }
    return true;
};
