import * as React from 'react';
import { render } from 'react-dom';
import { enableSentry } from '@websky/core/src/utils';
import { withConfig } from '@websky/core/src/config-hoc';
import merge from 'lodash.merge';
import Engine from './Engine';
import { config as defaultConfig } from '../../config';
enableSentry();
export const init = (mount, config) => {
    const Component = withConfig(Engine, merge({}, defaultConfig, config));
    render(React.createElement(Component, null), mount);
};
